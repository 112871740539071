import { Injectable } from '@angular/core';
import { CreateLimit, Limit, LimitFieldValue, StrategyLimitItems, UpdateLimit } from '@type/limits';
import { deepCopyObj } from '@utils/copy-obj';
import { ModelComparator } from '@utils/model-comparator/model-comparator';

@Injectable()
export class StrategyLimitStateService {
  #limit: Limit = new Limit();

  #limitCopyMap = new Map<number, Limit>();

  resetLimit = () => {
    this.#limit = new Limit();
  };

  resetState = () => {
    this.resetLimit();
    this.#limitCopyMap.clear();
  };

  setLimit(limit: Limit) {
    this.#limit = limit;
    !this.#limitCopyMap.has(limit.id) && this.#limitCopyMap.set(limit.id, deepCopyObj(limit));
  }

  getCurrentLimit() {
    return this.#limit;
  }

  getCurrentLimitCopy(limitId: number) {
    return this.#limitCopyMap.get(limitId);
  }

  private toFieldValueAttributes = (lfv: LimitFieldValue) => ({
    ...lfv,
    strategy_field_value_ids: lfv.strategy_field_values.map(({ id }) => id),
  });

  prepareLimitsToBackend(limitList: Limit[]) {
    return limitList.map((limit) =>
      !!limit.id ? this.convertToUpdateLimit(limit).strategy_limit : this.convertToCreateLimit(limit).strategy_limit,
    );
  }

  private convertToCreateLimit = (limit: Limit): CreateLimit => ({
    strategy_limit: {
      operator: limit.operator,
      value: limit.value,
      strategy_limit_field_values_attributes: limit.strategy_limit_field_values.map(this.toFieldValueAttributes),
      strategy_limit_items_attributes: new ModelComparator<StrategyLimitItems, 'item_id'>()
        .byKey('item_id')
        .models([], limit.strategy_limit_items),
    },
  });

  private convertToUpdateLimit = (limit: Limit): UpdateLimit => ({
    strategy_limit: {
      id: limit.id,
      operator: limit.operator,
      value: limit.value,
      ...this.getUpdateLimitAttributes(limit),
    },
  });

  private getUpdateLimitAttributes = (limit: Limit) => {
    return {
      strategy_limit_items_attributes: new ModelComparator<StrategyLimitItems, 'item_id'>()
        .byKey('item_id')
        .models(this.getCurrentLimitCopy(limit.id)?.strategy_limit_items || [], limit.strategy_limit_items),
      strategy_limit_field_values_attributes: limit.strategy_limit_field_values.map(this.toFieldValueAttributes),
    };
  };
}
