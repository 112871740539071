import { Operator } from '@enums/operator';

export const ICON_PATH = {
  [Operator['=']]: 'assets/icons/math/icon-is.svg',
  [Operator['<']]: 'assets/icons/math/icon-less.svg',
  [Operator['<=']]: 'assets/icons/math/icon-equal-less.svg',
  [Operator['>']]: 'assets/icons/math/icon-greater.svg',
  [Operator['>=']]: 'assets/icons/math/icon-equal-greater.svg',
};

export const MENU_OPTIONS = [
  {
    value: Operator['='],
    name: 'limits.form.menu.1',
    iconPath: ICON_PATH[Operator['=']],
  },
  {
    value: Operator['<'],
    name: 'limits.form.menu.2',
    iconPath: ICON_PATH[Operator['<']],
  },
  {
    value: Operator['<='],
    name: 'limits.form.menu.3',
    iconPath: ICON_PATH[Operator['<=']],
  },
  {
    value: Operator['>'],
    name: 'limits.form.menu.4',
    iconPath: ICON_PATH[Operator['>']],
  },
  {
    value: Operator['>='],
    name: 'limits.form.menu.5',
    iconPath: ICON_PATH[Operator['>=']],
  },
];
